import React from "react";
import { useNavigate } from "react-router-dom";

// Components
import Button from "@button/Button";

// Assets
import { ReactComponent as BackIcon } from "@icons/back.svg";
import { ReactComponent as CloseIcon } from "@icons/close.svg";
import { ReactComponent as MenuIcon } from "@icons/menu.svg";
import { getClassNames } from "@/utils/helpers";

type HeaderProps = {
  title?: string;
  isHome?: boolean;
  showDemoLink?: boolean;
  showBackButton?: boolean;
};

const Header = ({
  title,
  isHome,
  showDemoLink,
  showBackButton = true,
}: HeaderProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={getClassNames(
        "z-10",
        isHome ? "header-gradient" : "border-b border-b-white2/10"
      )}
    >
      <div className="container-auth flex items-center justify-between py-8">
        {showBackButton ? (
          <BackIcon
            onClick={() => navigate(-1)}
            className="cursor-pointer hover:scale-95"
          />
        ) : (
          <span></span>
        )}
        {title && <h1 className="text-[2.5rem] font-medium">{title}</h1>}
        <div className="flex items-center gap-2">
          {showDemoLink && (
            <Button
              title="Demo Guide"
              onClick={() =>
                window.open(
                  "https://trudenty.notion.site/Demo-Guide-2fc3c22365794d73b00dc5d1a3dcb157",
                  "_blank"
                )
              }
              color=""
              className="text-xl text-white bg-[#6B6B6B80] py-3 hover:scale-95"
            />
          )}

          {!isHome && (
            <CloseIcon
              className="cursor-pointer hover:scale-95"
              onClick={() => navigate("/")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;

import { useNavigate } from "react-router-dom";

import Header from "@components/header";

// Assets
import { ReactComponent as ShopperTrustIndexSetup } from "@images/shopper-trust-index-setup.svg";
import { ReactComponent as TrustNetwork } from "@images/trust-network.svg";
import useCaseCard from "@images/use-case-cards.png";

const Menu = () => {
  const navigate = useNavigate();
  return (
    <div className="container-auth mx-auto min-h-screen flex flex-col">
      <Header title="Experience what Trudenty can do for you" isHome={true} showBackButton={false} />
      <div className="flex-1 flex items-center">
        <div className="grid grid-cols-[3fr,2fr] gap-4 w-full">
          <div className="flex flex-col gap-4">
            <div
              className="grid grid-cols-[1fr,0px] items-center radius-1 gradient-5 h-fit max-h-[231px] overflow-hidden cursor-pointer"
              onClick={() => navigate("/overview")}
            >
              <div className="flex flex-col gap-6 min-h-[231px] w-full">
                <h3 className="header3 border-header text-white px-12 h-16 mt-5">
                  Preventing first-party fraud with the Trudenty Trust Network
                </h3>
                <div className="flex flex-row w-full pr-10">
                  <p className="paragraph-large text-white px-12 w-[70%]">
                    Explore the Trudenty Trust Network is and how it helps
                    online merchants prevent first-party fraud by collaborating
                    through data sharing.
                  </p>
                  <TrustNetwork className="w-[30%]" />
                </div>
              </div>
            </div>
            <div
              className="radius-1 gradient-1 h-fit flex flex-col cursor-pointer"
              onClick={() => navigate("/select-demo")}
            >
              <h3 className="header3 border-header text-white px-12 h-16">
                Product demo
              </h3>
              <p className="paragraph-large text-white flex-1 px-12 mt-6 ">
                Explore how the Trudenty console enables you to configure and
                integrate consumer fraud risk intelligence into merchant
                payments, supporting different use cases.
              </p>
              <div className="pl-12 mt-6 ">
                <ShopperTrustIndexSetup className="w-full h-full" />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div
              className="gradient-1 radius-1 min-h-[343px] flex flex-col cursor-pointer"
              onClick={() => navigate("/use-cases-guide")}
            >
              <h1 className="header3 border-header text-white px-10 h-16">
                Use cases and benefits
              </h1>
              <p className="px-10 pt-4 text-white paragraph-large">
                Merchant use cases supported by the Trudenty Trust Network
              </p>
              <div className="flex-1 flex items-end pl-10 overflow-hidden">
                <img src={useCaseCard} alt="use case card" className="" />
              </div>
            </div>
            <div className="gradient-1 radius-1 min-h-[260px] flex flex-col">
              <h1 className="header3 border-header text-white px-10 h-16">
                Product trailer
              </h1>
              <div className="flex-1 px-10 pb-6 relative">
                <div
                  className="overlay absolute inset-0 cursor-pointer"
                  onClick={() => navigate("/value-proposition")}
                ></div>
                <iframe
                  title="value proposition"
                  src="https://www.youtube.com/embed/md-HL7-icv0"
                  allowFullScreen
                  className="h-full rounded-2xl w-full"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
